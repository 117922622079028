import { useState } from 'react';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';

import { ExclamationMarkIcon, LoadingCircle } from '../../Atoms/Icons';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import { styled } from '../../stitches.config';
import { H1, H2 } from '../../Atoms/Typography/Headings/Heading';
import Button from '../../Atoms/Buttons/Button';
import { ChangeExpiredPassword } from '../LoginPage/Account';
import ChangePasswordPageModel from '../../ChangePasswordPage/Models/ChangePasswordPageModel.interface';
import UserCredentials from '../../LoginPage/Models/UserCredentials.interface';
import Input from '../../Atoms/Input/Input';
import { InputValidation } from '../../Atoms/Input/InputValidation';

function ChangePasswordPage() {
  const { pageHeading, pageIntroText, channelId, pageId } =
    useCurrentPage<ChangePasswordPageModel>();
  const [username, setUsername] = useState<string>('');
  const [disabled, setDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [validationMessage, setValidationMessage] = useState<string>('');
  const [btnEnabled, setBtnEnabled] = useState<boolean>(false);
  const [password, setPassword] = useState({
    currentPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  });

  const {
    'account/signIn': signIn,
    'account/changePassword': changePassword,
    'account/userName': userLabel,
    'customerInfo/currentPassword': currentPasswordLabel,
    'customerInfo/newPassword': newPasswordLabel,
    'customerInfo/newPasswordConfirm': newPasswordConfirmLabel,
    'customerInfo/validation/password': passwordValidationLabel,
    'customerInfo/validation/passwordNotmatch': passwordNotmatch,
  } = useTranslationData();

  const { languageRoute, staticPages } = useAppSettingsData();

  const userCredentials: UserCredentials = {
    userName: username,
    password: password.currentPassword,
    language: languageRoute,
    newPassword: password.newPassword,
  };

  const sendChangExpierPassword = () => {
    if (password.newPassword !== password.newPasswordConfirm) {
      setError(true);
      setIsLoading(false);
      setDisabled(false);
      setValidationMessage(passwordNotmatch);
      return;
    }
    ChangeExpiredPassword(
      userCredentials,
      staticPages.loginPage,
      channelId,
      pageId,
      setSuccess,
      setError,
      setValidationMessage,
      setIsLoading
    );
  };

  const onInputsValid = () => {
    setBtnEnabled(true);
  };

  const onInputsInvalid = () => {
    setBtnEnabled(false);
  };

  const handeSetPassword = (name: string, value: string) => {
    const data = { ...password } as any;
    data[name] = value;

    setPassword(data);
  };

  const handleUpdatePassword = async () => {
    if (disabled) return;
    setIsLoading(true);
    setDisabled(true);
    sendChangExpierPassword();
  };

  return (
    <main>
      <Container>
        <InfoGridBox>
          <H1 noMargin={true}>{pageHeading}</H1>
          <IntroText>{pageIntroText}</IntroText>
        </InfoGridBox>
        <FormGridBox>
          <FormContainer>
            <StyledH2 noMargin={true}>{changePassword}</StyledH2>
            <FromDetailText>{}</FromDetailText>
            {(error || success) && (
              <ValidationMessage success={success}>
                <ExclamationMarkIcon
                  color={success ? 'white' : 'primary'}
                  css={IconStyleError}
                />
                <ErrorMessage>{validationMessage}</ErrorMessage>
              </ValidationMessage>
            )}
            <InputValidation
              onInputsValid={onInputsValid}
              onInputsInvalid={onInputsInvalid}
            >
              <Input
                type="text"
                name="userName"
                title={userLabel}
                onChange={(value: string) => setUsername(value)}
                onKeyDown={(e: any) =>
                  e.key === 'Enter' && handleUpdatePassword()
                }
                validation={{
                  required: true,
                  minLength: 8,
                  errorMessage: passwordValidationLabel,
                }}
              />
              <Input
                type="password"
                name="Password"
                title={currentPasswordLabel}
                onChange={(value: string) =>
                  handeSetPassword('currentPassword', value)
                }
                onKeyDown={(e: any) =>
                  e.key === 'Enter' && handleUpdatePassword()
                }
                validation={{
                  required: true,
                  minLength: 8,
                  errorMessage: passwordValidationLabel,
                }}
              />
              <Input
                type="password"
                name="NewPassword"
                title={newPasswordLabel}
                onChange={(value: string) =>
                  handeSetPassword('newPassword', value)
                }
                onKeyDown={(e: any) =>
                  e.key === 'Enter' && handleUpdatePassword()
                }
                validation={{
                  required: true,
                  minLength: 8,
                  errorMessage: passwordValidationLabel,
                }}
              />
              <Input
                type="password"
                name="NewPasswordConfirm"
                title={newPasswordConfirmLabel}
                onChange={(value: string) =>
                  handeSetPassword('newPasswordConfirm', value)
                }
                onKeyDown={(e: any) =>
                  e.key === 'Enter' && handleUpdatePassword()
                }
                validation={{
                  required: true,
                  minLength: 8,
                  errorMessage: passwordValidationLabel,
                }}
              />
            </InputValidation>
            <ButtonContainer>
              <Button
                disabled={disabled}
                onClick={handleUpdatePassword}
                type={'secondary'}
              >
                {isLoading ? (
                  <LoadingCircle css={StyledLoadingCircle} isLoading />
                ) : (
                  <>{changePassword}</>
                )}
              </Button>
            </ButtonContainer>
          </FormContainer>
        </FormGridBox>
      </Container>
    </main>
  );
}

export default ChangePasswordPage;

const StyledLoadingCircle = {
  color: '$white',
  h: 4,
  mx: 'auto',
  my: 'auto',
};

const IconStyleError = {
  wh: 4,
  mr: 2,
  mt: 1,
};

const Container = styled('div', {
  display: 'grid',
  gg: 4,
  gridTemplateColumns: '1fr',
  wh: '100%',
  minH: '95vh',
  justifyContent: 'center',
  grg: 0,
  mx: 'auto',
  '@mediaMinLarge': {
    gridTemplateColumns: '2fr 3.5fr',
    gg: 0,
  },
  maxW: '$contentMaxWidth',
});

const GridBox = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  p: 8,
});

const InfoGridBox = styled(GridBox, {});

const FormGridBox = styled(GridBox, {});

const StyledH2 = styled(H2, {
  pb: 3,
});

const IntroText = styled('p', {
  lineHeight: '$lh1625',
  ls: '$ls08',
  color: '$accountTextPrimary',
});

const FormContainer = styled('div', {
  fs: 13,
  fontWeight: '$fw300',
  lineHeight: '$lh15',
  ls: '$ls125',
  py: 4,
  '@mediaMinLarge': {
    px: 47,
    py: 6,
  },
});

const FromDetailText = styled('p', {
  fs: 8,
  fontWeight: '$fw400',
  lineHeight: '$lh1625',
  mb: 6,
  mt: 0,
});

const ValidationMessage = styled('div', {
  fs: 8,
  fontWeight: '$fw400',
  lineHeight: '$lh1625',
  backgroundColor: '$errorBackground',
  color: '$errorText',
  p: 6,
  my: 6,
  display: 'flex',
  variants: {
    success: {
      true: {
        backgroundColor: '$successBackground',
        color: '$successText',
      },
    },
  },
});

const ErrorMessage = styled('p', {
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: '0%',
  m: 0,
});

const ButtonContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  mt: 8,
});
